import {
  ARK,
  Minecraft,
  Modded,
  Valhime,
  ARKCard,
  ModdedCard,
  MinecraftCard,
  ValhimeCard,
} from "../../components/Image";

export const gameitems = [
  {
    id: 0,
    url: ModdedCard,
    title: "MODDED MINECRAFT",
    price1: 17.09,
    cpu1: 4,
    ram1: 6,
    ssd1: 40,
    price2: 25.03,
    cpu2: 6,
    ram2: 10,
    ssd2: 100,
    box: "https://www.creeperhost.net/cdn-cgi/image/width=71,height=71,quality=95,fit=pad,background=transparent,onerror=redirect/https://cdn.creeper.host/shared/gameIcons/moddedmc.png",
    background: Modded,
    repotag: "itzg/minecraft-server:latest",
    port: [34001],
    containerData: "/data/world /data",
    domains: [""],
    environmentParameters: ["EULA=TRUE", "TYPE=FTBA"],
    containerPorts: [25565],
    checkout1: "7331a4b4-038e-425d-b6b9-8ed2c2f7510c",
    checkout2: "4e939cbf-af5e-4368-8e33-643a98449159",
  },
  {
    id: 1,
    url: MinecraftCard,
    title: "MINECRAFT: JAVA",
    price1: 9.97,
    cpu1: 2,
    ram1: 4,
    ssd1: 40,
    price2: 18.41,
    cpu2: 4,
    ram2: 8,
    ssd2: 100,
    box: "https://www.creeperhost.net/cdn-cgi/image/width=71,height=71,quality=95,fit=pad,background=transparent,onerror=redirect/https://cdn.creeper.host/shared/gameIcons/minecraft.png",
    background: Minecraft,
    repotag: "itzg/minecraft-server:latest",
    port: [34001],
    containerData: "/data/world /data",
    domains: [""],
    environmentParameters: ["EULA=TRUE", "TYPE=SPIGOT"],
    containerPorts: [25565],
    checkout1: "377200ba-49d3-4838-b218-af2ec3216337",
    checkout2: "4a989922-bd86-4479-813f-14afa1b57cc2",
  },
  {
    id: 2,
    url: ARKCard,
    title: "ARK: SURVIVAL EVOLVED",
    price1: 15.11,
    cpu1: 2,
    ram1: 8,
    ssd1: 80,
    price2: 23.27,
    cpu2: 4,
    ram2: 12,
    ssd2: 150,
    box: "https://www.creeperhost.net/cdn-cgi/image/width=71,height=71,quality=95,fit=pad,background=transparent,onerror=redirect/https://cdn.creeper.host/shared/gameIcons/ark.png",
    background: ARK,
    repotag: "wickedsensation/ark:1.0.0",
    port: [34001, 34002, 34003, 34004],
    containerData: "/app /home/steam/ARK-Backups",
    domains: ["", "", "", ""],
    environmentParameters: ["ADMIN_PASSWORD=root"],
    containerPorts: [7777, 7778, 27020, 27015],
    checkout1: "f9a24544-b2a8-4ddd-be4b-929e37c80906",
    checkout2: "98e36d4f-bf4a-438d-9140-bc2372a96488",
  },
  {
    id: 3,
    url: ValhimeCard,
    title: "VALHEIM",
    price1: 9.97,
    cpu1: 2,
    ram1: 4,
    ssd1: 40,
    price2: 13.84,
    cpu2: 3,
    ram2: 6,
    ssd2: 80,
    box: "https://www.creeperhost.net/cdn-cgi/image/width=71,height=71,quality=95,fit=pad,background=transparent,onerror=redirect/https://cdn.creeper.host/shared/gameIcons/valheim.png",
    background: Valhime,
    repotag: "wickedsensation/valheim:1.0.1",
    port: [34001, 34002],
    containerData: "/opt/valheim /config",
    domains: ["", ""],
    environmentParameters: [],
    containerPorts: [2456, 2457],
    checkout1: "d98f357f-d10a-45d2-a534-ceabcd6b3dd9",
    checkout2: "df0fe168-5312-47fb-999d-37c57d45d35e",
  },
];
